// export const URL = "https://192.168.1.70:7252";
export const URL = "https://ndcggc.egadevapi.com"; //live

export const METADATA = {
  custkey: "keY-a6ee91c7-493a-4e0d-814d-06919674cea1-Ey",
  custpassword: "passW-5e4cd4d1-515b-4047-aa63-288be83e44ff-Word",
};

export const UPGRADE = 0;
export const MONTHLY_SUBSCRIPTION = 1;
export const CARD_REPRINT = 2;
