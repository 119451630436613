/**
 * @fileoverview gRPC-Web generated client stub for Members
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v4.25.1
// source: Members.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');

const proto = {};
proto.Members = require('./Members_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.Members.MembersSvcClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.Members.MembersSvcPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Members.MemberRequest,
 *   !proto.Members.CreateMemberResponse>}
 */
const methodDescriptor_MembersSvc_CreateMember = new grpc.web.MethodDescriptor(
  '/Members.MembersSvc/CreateMember',
  grpc.web.MethodType.UNARY,
  proto.Members.MemberRequest,
  proto.Members.CreateMemberResponse,
  /**
   * @param {!proto.Members.MemberRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Members.CreateMemberResponse.deserializeBinary
);


/**
 * @param {!proto.Members.MemberRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.Members.CreateMemberResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Members.CreateMemberResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Members.MembersSvcClient.prototype.createMember =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Members.MembersSvc/CreateMember',
      request,
      metadata || {},
      methodDescriptor_MembersSvc_CreateMember,
      callback);
};


/**
 * @param {!proto.Members.MemberRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Members.CreateMemberResponse>}
 *     Promise that resolves to the response
 */
proto.Members.MembersSvcPromiseClient.prototype.createMember =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Members.MembersSvc/CreateMember',
      request,
      metadata || {},
      methodDescriptor_MembersSvc_CreateMember);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Members.MemberUssdRequest,
 *   !proto.Members.MemberResponse>}
 */
const methodDescriptor_MembersSvc_CreateMemberUssd = new grpc.web.MethodDescriptor(
  '/Members.MembersSvc/CreateMemberUssd',
  grpc.web.MethodType.UNARY,
  proto.Members.MemberUssdRequest,
  proto.Members.MemberResponse,
  /**
   * @param {!proto.Members.MemberUssdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Members.MemberResponse.deserializeBinary
);


/**
 * @param {!proto.Members.MemberUssdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.Members.MemberResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Members.MemberResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Members.MembersSvcClient.prototype.createMemberUssd =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Members.MembersSvc/CreateMemberUssd',
      request,
      metadata || {},
      methodDescriptor_MembersSvc_CreateMemberUssd,
      callback);
};


/**
 * @param {!proto.Members.MemberUssdRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Members.MemberResponse>}
 *     Promise that resolves to the response
 */
proto.Members.MembersSvcPromiseClient.prototype.createMemberUssd =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Members.MembersSvc/CreateMemberUssd',
      request,
      metadata || {},
      methodDescriptor_MembersSvc_CreateMemberUssd);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Members.GetMembersRequest,
 *   !proto.Members.MemberListResponse>}
 */
const methodDescriptor_MembersSvc_GetMembers = new grpc.web.MethodDescriptor(
  '/Members.MembersSvc/GetMembers',
  grpc.web.MethodType.UNARY,
  proto.Members.GetMembersRequest,
  proto.Members.MemberListResponse,
  /**
   * @param {!proto.Members.GetMembersRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Members.MemberListResponse.deserializeBinary
);


/**
 * @param {!proto.Members.GetMembersRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.Members.MemberListResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Members.MemberListResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Members.MembersSvcClient.prototype.getMembers =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Members.MembersSvc/GetMembers',
      request,
      metadata || {},
      methodDescriptor_MembersSvc_GetMembers,
      callback);
};


/**
 * @param {!proto.Members.GetMembersRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Members.MemberListResponse>}
 *     Promise that resolves to the response
 */
proto.Members.MembersSvcPromiseClient.prototype.getMembers =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Members.MembersSvc/GetMembers',
      request,
      metadata || {},
      methodDescriptor_MembersSvc_GetMembers);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Members.GetMemberSearchRequest,
 *   !proto.Members.Member>}
 */
const methodDescriptor_MembersSvc_GetMemberBySearchData = new grpc.web.MethodDescriptor(
  '/Members.MembersSvc/GetMemberBySearchData',
  grpc.web.MethodType.UNARY,
  proto.Members.GetMemberSearchRequest,
  proto.Members.Member,
  /**
   * @param {!proto.Members.GetMemberSearchRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Members.Member.deserializeBinary
);


/**
 * @param {!proto.Members.GetMemberSearchRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.Members.Member)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Members.Member>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Members.MembersSvcClient.prototype.getMemberBySearchData =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Members.MembersSvc/GetMemberBySearchData',
      request,
      metadata || {},
      methodDescriptor_MembersSvc_GetMemberBySearchData,
      callback);
};


/**
 * @param {!proto.Members.GetMemberSearchRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Members.Member>}
 *     Promise that resolves to the response
 */
proto.Members.MembersSvcPromiseClient.prototype.getMemberBySearchData =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Members.MembersSvc/GetMemberBySearchData',
      request,
      metadata || {},
      methodDescriptor_MembersSvc_GetMemberBySearchData);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Members.MemberLoginRequest,
 *   !proto.Members.MemberLoginResponse>}
 */
const methodDescriptor_MembersSvc_LoginMember = new grpc.web.MethodDescriptor(
  '/Members.MembersSvc/LoginMember',
  grpc.web.MethodType.UNARY,
  proto.Members.MemberLoginRequest,
  proto.Members.MemberLoginResponse,
  /**
   * @param {!proto.Members.MemberLoginRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Members.MemberLoginResponse.deserializeBinary
);


/**
 * @param {!proto.Members.MemberLoginRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.Members.MemberLoginResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Members.MemberLoginResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Members.MembersSvcClient.prototype.loginMember =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Members.MembersSvc/LoginMember',
      request,
      metadata || {},
      methodDescriptor_MembersSvc_LoginMember,
      callback);
};


/**
 * @param {!proto.Members.MemberLoginRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Members.MemberLoginResponse>}
 *     Promise that resolves to the response
 */
proto.Members.MembersSvcPromiseClient.prototype.loginMember =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Members.MembersSvc/LoginMember',
      request,
      metadata || {},
      methodDescriptor_MembersSvc_LoginMember);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Members.MemberExistRequest,
 *   !proto.Members.MemberExistResponse>}
 */
const methodDescriptor_MembersSvc_CheckIfMemberExist = new grpc.web.MethodDescriptor(
  '/Members.MembersSvc/CheckIfMemberExist',
  grpc.web.MethodType.UNARY,
  proto.Members.MemberExistRequest,
  proto.Members.MemberExistResponse,
  /**
   * @param {!proto.Members.MemberExistRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Members.MemberExistResponse.deserializeBinary
);


/**
 * @param {!proto.Members.MemberExistRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.Members.MemberExistResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Members.MemberExistResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Members.MembersSvcClient.prototype.checkIfMemberExist =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Members.MembersSvc/CheckIfMemberExist',
      request,
      metadata || {},
      methodDescriptor_MembersSvc_CheckIfMemberExist,
      callback);
};


/**
 * @param {!proto.Members.MemberExistRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Members.MemberExistResponse>}
 *     Promise that resolves to the response
 */
proto.Members.MembersSvcPromiseClient.prototype.checkIfMemberExist =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Members.MembersSvc/CheckIfMemberExist',
      request,
      metadata || {},
      methodDescriptor_MembersSvc_CheckIfMemberExist);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Members.JMRequest,
 *   !proto.Members.JMResponse>}
 */
const methodDescriptor_MembersSvc_CreateJMMember = new grpc.web.MethodDescriptor(
  '/Members.MembersSvc/CreateJMMember',
  grpc.web.MethodType.UNARY,
  proto.Members.JMRequest,
  proto.Members.JMResponse,
  /**
   * @param {!proto.Members.JMRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Members.JMResponse.deserializeBinary
);


/**
 * @param {!proto.Members.JMRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.Members.JMResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Members.JMResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Members.MembersSvcClient.prototype.createJMMember =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Members.MembersSvc/CreateJMMember',
      request,
      metadata || {},
      methodDescriptor_MembersSvc_CreateJMMember,
      callback);
};


/**
 * @param {!proto.Members.JMRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Members.JMResponse>}
 *     Promise that resolves to the response
 */
proto.Members.MembersSvcPromiseClient.prototype.createJMMember =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Members.MembersSvc/CreateJMMember',
      request,
      metadata || {},
      methodDescriptor_MembersSvc_CreateJMMember);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Members.MemberRequest,
 *   !proto.Members.CreateMemberResponse>}
 */
const methodDescriptor_MembersSvc_CreateMemberByAgent = new grpc.web.MethodDescriptor(
  '/Members.MembersSvc/CreateMemberByAgent',
  grpc.web.MethodType.UNARY,
  proto.Members.MemberRequest,
  proto.Members.CreateMemberResponse,
  /**
   * @param {!proto.Members.MemberRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Members.CreateMemberResponse.deserializeBinary
);


/**
 * @param {!proto.Members.MemberRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.Members.CreateMemberResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Members.CreateMemberResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Members.MembersSvcClient.prototype.createMemberByAgent =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Members.MembersSvc/CreateMemberByAgent',
      request,
      metadata || {},
      methodDescriptor_MembersSvc_CreateMemberByAgent,
      callback);
};


/**
 * @param {!proto.Members.MemberRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Members.CreateMemberResponse>}
 *     Promise that resolves to the response
 */
proto.Members.MembersSvcPromiseClient.prototype.createMemberByAgent =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Members.MembersSvc/CreateMemberByAgent',
      request,
      metadata || {},
      methodDescriptor_MembersSvc_CreateMemberByAgent);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Members.ResetPinRequest,
 *   !proto.Members.ResetPinResponse>}
 */
const methodDescriptor_MembersSvc_ResetMemberPin = new grpc.web.MethodDescriptor(
  '/Members.MembersSvc/ResetMemberPin',
  grpc.web.MethodType.UNARY,
  proto.Members.ResetPinRequest,
  proto.Members.ResetPinResponse,
  /**
   * @param {!proto.Members.ResetPinRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Members.ResetPinResponse.deserializeBinary
);


/**
 * @param {!proto.Members.ResetPinRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.Members.ResetPinResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Members.ResetPinResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Members.MembersSvcClient.prototype.resetMemberPin =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Members.MembersSvc/ResetMemberPin',
      request,
      metadata || {},
      methodDescriptor_MembersSvc_ResetMemberPin,
      callback);
};


/**
 * @param {!proto.Members.ResetPinRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Members.ResetPinResponse>}
 *     Promise that resolves to the response
 */
proto.Members.MembersSvcPromiseClient.prototype.resetMemberPin =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Members.MembersSvc/ResetMemberPin',
      request,
      metadata || {},
      methodDescriptor_MembersSvc_ResetMemberPin);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Members.ChangeMemberNameRequest,
 *   !proto.Members.ChangeMemberNameResponse>}
 */
const methodDescriptor_MembersSvc_ChangeMemberName = new grpc.web.MethodDescriptor(
  '/Members.MembersSvc/ChangeMemberName',
  grpc.web.MethodType.UNARY,
  proto.Members.ChangeMemberNameRequest,
  proto.Members.ChangeMemberNameResponse,
  /**
   * @param {!proto.Members.ChangeMemberNameRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Members.ChangeMemberNameResponse.deserializeBinary
);


/**
 * @param {!proto.Members.ChangeMemberNameRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.Members.ChangeMemberNameResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Members.ChangeMemberNameResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Members.MembersSvcClient.prototype.changeMemberName =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Members.MembersSvc/ChangeMemberName',
      request,
      metadata || {},
      methodDescriptor_MembersSvc_ChangeMemberName,
      callback);
};


/**
 * @param {!proto.Members.ChangeMemberNameRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Members.ChangeMemberNameResponse>}
 *     Promise that resolves to the response
 */
proto.Members.MembersSvcPromiseClient.prototype.changeMemberName =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Members.MembersSvc/ChangeMemberName',
      request,
      metadata || {},
      methodDescriptor_MembersSvc_ChangeMemberName);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Members.GetMemberCardStatusRequest,
 *   !proto.Members.MemberResponse>}
 */
const methodDescriptor_MembersSvc_UpdateMemberCardStatus = new grpc.web.MethodDescriptor(
  '/Members.MembersSvc/UpdateMemberCardStatus',
  grpc.web.MethodType.UNARY,
  proto.Members.GetMemberCardStatusRequest,
  proto.Members.MemberResponse,
  /**
   * @param {!proto.Members.GetMemberCardStatusRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Members.MemberResponse.deserializeBinary
);


/**
 * @param {!proto.Members.GetMemberCardStatusRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.Members.MemberResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Members.MemberResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Members.MembersSvcClient.prototype.updateMemberCardStatus =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Members.MembersSvc/UpdateMemberCardStatus',
      request,
      metadata || {},
      methodDescriptor_MembersSvc_UpdateMemberCardStatus,
      callback);
};


/**
 * @param {!proto.Members.GetMemberCardStatusRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Members.MemberResponse>}
 *     Promise that resolves to the response
 */
proto.Members.MembersSvcPromiseClient.prototype.updateMemberCardStatus =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Members.MembersSvc/UpdateMemberCardStatus',
      request,
      metadata || {},
      methodDescriptor_MembersSvc_UpdateMemberCardStatus);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Members.PrintingAfterDownloadRequest,
 *   !proto.Members.MemberResponse>}
 */
const methodDescriptor_MembersSvc_UpdateListOfMembersQualifiedForCardPrintingAfterDownload = new grpc.web.MethodDescriptor(
  '/Members.MembersSvc/UpdateListOfMembersQualifiedForCardPrintingAfterDownload',
  grpc.web.MethodType.UNARY,
  proto.Members.PrintingAfterDownloadRequest,
  proto.Members.MemberResponse,
  /**
   * @param {!proto.Members.PrintingAfterDownloadRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Members.MemberResponse.deserializeBinary
);


/**
 * @param {!proto.Members.PrintingAfterDownloadRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.Members.MemberResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Members.MemberResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Members.MembersSvcClient.prototype.updateListOfMembersQualifiedForCardPrintingAfterDownload =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Members.MembersSvc/UpdateListOfMembersQualifiedForCardPrintingAfterDownload',
      request,
      metadata || {},
      methodDescriptor_MembersSvc_UpdateListOfMembersQualifiedForCardPrintingAfterDownload,
      callback);
};


/**
 * @param {!proto.Members.PrintingAfterDownloadRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Members.MemberResponse>}
 *     Promise that resolves to the response
 */
proto.Members.MembersSvcPromiseClient.prototype.updateListOfMembersQualifiedForCardPrintingAfterDownload =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Members.MembersSvc/UpdateListOfMembersQualifiedForCardPrintingAfterDownload',
      request,
      metadata || {},
      methodDescriptor_MembersSvc_UpdateListOfMembersQualifiedForCardPrintingAfterDownload);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Members.MemberEmptyRequest,
 *   !proto.Members.CardStatusResponse>}
 */
const methodDescriptor_MembersSvc_GetListOfMembersQualifiedForCardPrinting = new grpc.web.MethodDescriptor(
  '/Members.MembersSvc/GetListOfMembersQualifiedForCardPrinting',
  grpc.web.MethodType.UNARY,
  proto.Members.MemberEmptyRequest,
  proto.Members.CardStatusResponse,
  /**
   * @param {!proto.Members.MemberEmptyRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Members.CardStatusResponse.deserializeBinary
);


/**
 * @param {!proto.Members.MemberEmptyRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.Members.CardStatusResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Members.CardStatusResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Members.MembersSvcClient.prototype.getListOfMembersQualifiedForCardPrinting =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Members.MembersSvc/GetListOfMembersQualifiedForCardPrinting',
      request,
      metadata || {},
      methodDescriptor_MembersSvc_GetListOfMembersQualifiedForCardPrinting,
      callback);
};


/**
 * @param {!proto.Members.MemberEmptyRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Members.CardStatusResponse>}
 *     Promise that resolves to the response
 */
proto.Members.MembersSvcPromiseClient.prototype.getListOfMembersQualifiedForCardPrinting =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Members.MembersSvc/GetListOfMembersQualifiedForCardPrinting',
      request,
      metadata || {},
      methodDescriptor_MembersSvc_GetListOfMembersQualifiedForCardPrinting);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Members.CardStatusRequest,
 *   !proto.Members.CardStatusResponse>}
 */
const methodDescriptor_MembersSvc_GetListOfMembersCurrentCardStatusByCardStatus = new grpc.web.MethodDescriptor(
  '/Members.MembersSvc/GetListOfMembersCurrentCardStatusByCardStatus',
  grpc.web.MethodType.UNARY,
  proto.Members.CardStatusRequest,
  proto.Members.CardStatusResponse,
  /**
   * @param {!proto.Members.CardStatusRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Members.CardStatusResponse.deserializeBinary
);


/**
 * @param {!proto.Members.CardStatusRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.Members.CardStatusResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Members.CardStatusResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Members.MembersSvcClient.prototype.getListOfMembersCurrentCardStatusByCardStatus =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Members.MembersSvc/GetListOfMembersCurrentCardStatusByCardStatus',
      request,
      metadata || {},
      methodDescriptor_MembersSvc_GetListOfMembersCurrentCardStatusByCardStatus,
      callback);
};


/**
 * @param {!proto.Members.CardStatusRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Members.CardStatusResponse>}
 *     Promise that resolves to the response
 */
proto.Members.MembersSvcPromiseClient.prototype.getListOfMembersCurrentCardStatusByCardStatus =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Members.MembersSvc/GetListOfMembersCurrentCardStatusByCardStatus',
      request,
      metadata || {},
      methodDescriptor_MembersSvc_GetListOfMembersCurrentCardStatusByCardStatus);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Members.CardStatusWithDateRequest,
 *   !proto.Members.CardStatusResponse>}
 */
const methodDescriptor_MembersSvc_GetListOfMembersHistoryCardStatusByCardStatus = new grpc.web.MethodDescriptor(
  '/Members.MembersSvc/GetListOfMembersHistoryCardStatusByCardStatus',
  grpc.web.MethodType.UNARY,
  proto.Members.CardStatusWithDateRequest,
  proto.Members.CardStatusResponse,
  /**
   * @param {!proto.Members.CardStatusWithDateRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Members.CardStatusResponse.deserializeBinary
);


/**
 * @param {!proto.Members.CardStatusWithDateRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.Members.CardStatusResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Members.CardStatusResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Members.MembersSvcClient.prototype.getListOfMembersHistoryCardStatusByCardStatus =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Members.MembersSvc/GetListOfMembersHistoryCardStatusByCardStatus',
      request,
      metadata || {},
      methodDescriptor_MembersSvc_GetListOfMembersHistoryCardStatusByCardStatus,
      callback);
};


/**
 * @param {!proto.Members.CardStatusWithDateRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Members.CardStatusResponse>}
 *     Promise that resolves to the response
 */
proto.Members.MembersSvcPromiseClient.prototype.getListOfMembersHistoryCardStatusByCardStatus =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Members.MembersSvc/GetListOfMembersHistoryCardStatusByCardStatus',
      request,
      metadata || {},
      methodDescriptor_MembersSvc_GetListOfMembersHistoryCardStatusByCardStatus);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Members.MemberEmptyRequest,
 *   !proto.Members.MemberResponse>}
 */
const methodDescriptor_MembersSvc_SendSMSToMembersForMonthlyDonation = new grpc.web.MethodDescriptor(
  '/Members.MembersSvc/SendSMSToMembersForMonthlyDonation',
  grpc.web.MethodType.UNARY,
  proto.Members.MemberEmptyRequest,
  proto.Members.MemberResponse,
  /**
   * @param {!proto.Members.MemberEmptyRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Members.MemberResponse.deserializeBinary
);


/**
 * @param {!proto.Members.MemberEmptyRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.Members.MemberResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Members.MemberResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Members.MembersSvcClient.prototype.sendSMSToMembersForMonthlyDonation =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Members.MembersSvc/SendSMSToMembersForMonthlyDonation',
      request,
      metadata || {},
      methodDescriptor_MembersSvc_SendSMSToMembersForMonthlyDonation,
      callback);
};


/**
 * @param {!proto.Members.MemberEmptyRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Members.MemberResponse>}
 *     Promise that resolves to the response
 */
proto.Members.MembersSvcPromiseClient.prototype.sendSMSToMembersForMonthlyDonation =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Members.MembersSvc/SendSMSToMembersForMonthlyDonation',
      request,
      metadata || {},
      methodDescriptor_MembersSvc_SendSMSToMembersForMonthlyDonation);
};


module.exports = proto.Members;

